.about-me {
  width: 100%;
  display: flex;
  flex-direction: column;

  p, .education {
    padding: 0px 3rem 0px 3rem;
  }

  .about-introduction {
    display: flex;
    align-items: center;

    img {
      border-radius: 50px;
      margin: 2rem 3rem 0px 3rem;

      box-shadow: -6px -6px 14px rgba(59, 59, 59, 0.7),
      -6px -6px 10px rgba(59, 59, 59, 0.5),
      6px 6px 8px rgba(59, 59, 59, 0.075),
      6px 6px 10px rgba(255, 255, 255, 0.15);
    }

    .about-introduction-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h2 {
        margin: 0px;
      }

      p {
        text-align: left;
      }      
    }
  }

  .education {
    .education-item {
      margin: 0rem 1.5rem 2.5rem 1.5rem;
      
      .education-item-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3, p {
          margin: 0px;
        }
      }
    }
  }

  .download {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0rem 5rem 0rem;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #4d4d4d;
      padding: 10px;
      border-radius: 15px;

      transition: .2s ease-in-out;
      box-shadow: -6px -6px 14px rgba(43, 43, 43, 0.7),
                -6px -6px 10px rgba(43, 43, 43, 0.5),
                6px 6px 8px rgba(43, 43, 43, 0.075),
                6px 6px 10px rgba(255, 255, 255, 0.15);
  
      &:hover {
        box-shadow: -2px -2px 6px rgba(43, 43, 43, 0.6),
                    -2px -2px 4px rgba(43, 43, 43, 0.4),
                    2px 2px 2px rgba(43, 43, 43, 0.05),
                    2px 2px 4px rgba(255, 255, 255, 0.1);
      }
      &:active {
        box-shadow: inset -2px -2px 6px rgba(43, 43, 43, 0.7),
                    inset -2px -2px 4px rgba(43, 43, 43, 0.5),
                    inset 2px 2px 2px rgba(43, 43, 43, 0.075),
                    inset 2px 2px 4px rgba(255, 255, 255, 0.15);
      }

      svg {
        height: 50px;
        width: 50px;
        color: white;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .about-me {
    p, .education {
      padding: 0px 1rem 0px 1rem;
    }

    .main-body {
      padding: 0px;
      height: 140vh;

      p {
        margin: 16px;
      }
    }
  }
}

@media only screen and (max-width: 560px) {
  .about-me {
    .about-introduction {
      img {
        display: none;
      }

      .about-introduction-content {
        align-items: center;
      }
    }

    .download {
      margin-bottom: 2rem;
      
      a {
        width: 100%;
        border-radius: 0px;
        box-shadow: none;
        border-bottom: solid #7A709D;
        border-top: solid #7A709D;
        padding-left: 0px;
        padding-right: 0px;
  
        &:hover {
          box-shadow: inset 5px 8px 20px 0px rgba(43, 43, 43, 0.7);
        }
  
        &:active {
          box-shadow: none;
        }
      }
    }
  }
}

@media only screen and (max-height: 1000px) {
  .about-me {
    .landing {
      background-position: center top;
    }
  }
}
