.photography {
  display: flex;
  flex-direction: column;
  align-items: center;

  p, h1, .photography-introduction, .photography-examples {
    padding: 0px 3rem 0px 3rem;
  }

  .photography-skills-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    .photography-skills-container, .photography-software-container {
      display: flex;
      width: 100%;
      margin: 0rem 0rem 0.5rem 0rem;

      h3 {
        width: 8rem;
        display: flex;
        flex-direction: row-reverse;
        margin: 0px;
      }

      ul {
        margin: 0px;
        padding: 0px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        
        li {
          display: inline;
          padding: 0px 0em 0px 0.5em;
        }
      }
    }
  }

  .photography-examples {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
    grid-auto-rows: minmax(100px, auto);
    margin-top: 3rem;

    .photography-example {
      display: flex;

      img {
        width: 40vw;
        height: auto;
        border-radius: 10px;
        margin: auto;

        transition: .2s ease-in-out;
        box-shadow: -6px -6px 14px rgba(43, 43, 43, 0.7),
                  -6px -6px 10px rgba(43, 43, 43, 0.5),
                  6px 6px 8px rgba(43, 43, 43, 0.075),
                  6px 6px 10px rgba(43, 43, 43, 0.15);
    
        &:hover {
          cursor: pointer;
          box-shadow: -2px -2px 6px rgba(43, 43, 43, 0.6),
                      -2px -2px 4px rgba(43, 43, 43, 0.4),
                      2px 2px 2px rgba(43, 43, 43, 0.05),
                      2px 2px 4px rgba(43, 43, 43, 0.1);
        }
      }           
    }
  }
}

@media only screen and (max-width: 1000px) {
  .photography {
    .photography-examples {
      display: flex;
      flex-direction: column;
      gap: 3rem;

      .photography-example {
        img {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 560px) {
  .photography {
    .photography-introduction, .photography-example, p, h1 {
      padding: 0px 1rem 0px 1rem;
    }
  }
}