.contact {
  display: flex;
  flex-direction: column;
  align-items: center;

  p, h1, .contact-introduction {
    padding: 0px 3rem 0px 3rem;
  }

  .contact-email {
    display: flex;
    gap: 1rem;
    margin-top: 3rem;

    a {
      display: flex;
      align-items: center;
      background-color: #4d4d4d;
      padding: 10px;
      text-decoration: none;
      font-size: small;
      color: white;
      font-size: medium;
      border-bottom: solid #7A709D;
    
      &:visited {
        color: white;
      }
  
      border-radius: 15px;
      transition: .2s ease-in-out;
      box-shadow: -6px -6px 14px rgba(43, 43, 43, 0.7),
                -6px -6px 10px rgba(43, 43, 43, 0.5),
                6px 6px 8px rgba(43, 43, 43, 0.075),
                6px 6px 10px rgba(255, 255, 255, 0.15);
  
      &:hover {
        border-bottom: solid #4d4d4d;
        box-shadow: -2px -2px 6px rgba(43, 43, 43, 0.6),
                    -2px -2px 4px rgba(43, 43, 43, 0.4),
                    2px 2px 2px rgba(43, 43, 43, 0.05),
                    2px 2px 4px rgba(255, 255, 255, 0.1);
      }
      &:active {
        box-shadow: inset -2px -2px 6px rgba(43, 43, 43, 0.7),
                    inset -2px -2px 4px rgba(43, 43, 43, 0.5),
                    inset 2px 2px 2px rgba(43, 43, 43, 0.075),
                    inset 2px 2px 4px rgba(255, 255, 255, 0.15);
      }      
    }
    
    button {
      background-color: #4d4d4d;
      border: none;

      svg {
        height: 40px;
        width: 40px;
        color: white;
      }

      border-radius: 15px;
      transition: .2s ease-in-out;
      box-shadow: -6px -6px 14px rgba(43, 43, 43, 0.7),
                -6px -6px 10px rgba(43, 43, 43, 0.5),
                6px 6px 8px rgba(43, 43, 43, 0.075),
                6px 6px 10px rgba(255, 255, 255, 0.15);

      &:hover {
        box-shadow: -2px -2px 6px rgba(43, 43, 43, 0.6),
                    -2px -2px 4px rgba(43, 43, 43, 0.4),
                    2px 2px 2px rgba(43, 43, 43, 0.05),
                    2px 2px 4px rgba(255, 255, 255, 0.1);
      }
      
      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 560px) {
  p, h1, .contact-introduction {
    padding: 0px 1rem 0px 1rem;
  }
}