.development {
  width: 100%;
  display: flex;
  flex-direction: column;

  p, h1, .skill-list, .development-projects {
    padding: 0px 3rem 0px 3rem;
  }

  .development-introduction {
    text-align: left;
  }

  .skill-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
            
    .skills-container, .languages-container, .tools-container {
      display: flex;
      width: 100%;
      margin: 0rem 0rem 0.5rem 0rem;

      h3 {
        width: 8rem;
        display: flex;
        flex-direction: row-reverse;
        margin: 0px;
      }

      ul {
        margin: 0px;
        padding: 0px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        
        li {
          display: inline;
          padding: 0px 0em 0px 0.5em;
        }
      }
    }
    
  }

  .development-projects {
    .project {
      display: flex;
      flex-direction: column;
      margin: 3rem 1.5rem 5rem 1.5rem;

      h2, h3, p {
        margin: 0px;
      }

      img {
        width: 70vw;
        height: auto;
        border-radius: 50px;
        margin: 2.5rem auto 0px auto;
      }

      .sub-sections {
        display: flex;
        flex-direction: row;

        .sub-section {
          display: flex;
          flex-direction: column;
          margin: 1.5rem 0px 0px 0px;
          width: 50%;

          p {
            height: 100%;
          }

          a {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #4d4d4d;
            padding: 10px;
            border-radius: 15px;
            width: 3rem;
            margin: 1.5rem auto 0px auto;
      
            transition: .2s ease-in-out;
            box-shadow: -6px -6px 14px rgba(43, 43, 43, 0.7),
                      -6px -6px 10px rgba(43, 43, 43, 0.5),
                      6px 6px 8px rgba(43, 43, 43, 0.075),
                      6px 6px 10px rgba(255, 255, 255, 0.15);
        
            &:hover {
              box-shadow: -2px -2px 6px rgba(43, 43, 43, 0.6),
                          -2px -2px 4px rgba(43, 43, 43, 0.4),
                          2px 2px 2px rgba(43, 43, 43, 0.05),
                          2px 2px 4px rgba(255, 255, 255, 0.1);
            }
            &:active {
              box-shadow: inset -2px -2px 6px rgba(43, 43, 43, 0.7),
                          inset -2px -2px 4px rgba(43, 43, 43, 0.5),
                          inset 2px 2px 2px rgba(43, 43, 43, 0.075),
                          inset 2px 2px 4px rgba(255, 255, 255, 0.15);
            }
      
            svg {
              height: 50px;
              width: 50px;
              color: white;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .development {
    p, h1, .skill-list, .development-projects {
      padding: 0px 1rem 0px 1rem;
    }

    .development-projects {
      .project {
        img {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .development {
    .development-projects {
      .project {
        img {
          display: none;
        }

        .sub-sections {
          flex-direction: column;
          
          .sub-section {
            width: 100%;
          }
        }
      }
    }
  }
}