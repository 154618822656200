$landing-bg: "../../public/media/bg2.jpeg";

.about-me, .development, .design, .photography, .contact {
  min-height: 100vh;
  max-width: 75rem;
  margin: auto;
}

.min-height {
  min-height: 120vh;
}

p {
  text-align: left;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
}

.splash {
  display: flex;
  flex-direction: row-reverse;
  background-color: #75419D;
  border-radius: 67px 67px 67px 0px;
  height: 260px;
  width: 385px;
  margin: 2rem 0px 0px 2rem ;

  box-shadow: -6px -6px 14px rgba(105, 105, 105, 0.1),
  -6px -6px 10px rgba(105, 105, 105, .5),
  6px 6px 8px rgba(105, 105, 105, .075),
  6px 6px 10px rgba(255, 255, 255, .15);

  .inner-bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #7A709D;
    border-radius: 67px 67px 67px 0px;
    width: 100%;
    margin-left: 25px;
    margin-bottom: 20px;
    padding: 15px 25px 0px 25px;
    text-align: left;
    
    hr {
      width: 100%;
    }

    h1, h3, p {
      margin: 0px;
    }

    h1 {
      font-size: 3em;
      line-height: 1;
    }

    p {
      font-style: italic;
    }
  }
}

.landing {
  background-image: url($landing-bg);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: cover;
  height: 100vh;

  .splash-content {
    display: flex;
    justify-content: space-between;
    height: calc(100% - 60px);

    .landing-ribbon {
      background-color: #7A709D;
      width: 60px;
      display: flex;
      flex-direction: row-reverse;
      box-shadow: -10px -14px 20px -6px rgba(0, 0, 0, 0.25);

      .inner-landing-ribbon {
        background-color: #75419D;
        width: 50%;
      }
    }      
  }

  .mid-ribbon-container {
    display: flex;
    width: 100%;
    box-shadow: 10px 14px 20px -6px rgba(59, 59, 59, .9), -10px -14px 20px -6px rgba(0, 0, 0, 0.25);
    
    .mid-ribbon-corner-l {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      background-color: #7A709D;
      width: 60px;

      .inner-mid-ribbon-corner-l {
        background-color: #75419D;
        width: 50%;
        height: 50%;
      }
    }

    .mid-ribbon-corner-r {
      background-color: #75419D;
      width: 60px;

      .inner-mid-ribbon-corner-r {
        background-color: #7A709D;
        width: 50%;
        height: 50%;
      }
    }

    .mid-ribbon {
      display: flex;
      flex-direction: column;
      background-color: #75419D;
      height: 60px;
      width: calc(100% - 120px);

      .inner-mid-ribbon {
        background-color: #7A709D;
        height: 50%;
      }
    }      
  }
}

.nav {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 2.75rem 0px 1.75rem 0px;

  .nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7rem;
    height: 30px;
    padding: 10px;
    color: white;
    background-color: #4d4d4d;
    text-decoration: none;
    border-bottom: solid #7A709D;

    // Thank you Naoya from codepen for this neumorphism effect https://codepen.io/nxworld/pen/OJVJQme
    border-radius: .5rem;
    transition: .2s ease-in-out;
    box-shadow: -6px -6px 14px rgba(43, 43, 43, 0.7),
              -6px -6px 10px rgba(43, 43, 43, 0.5),
              6px 6px 8px rgba(43, 43, 43, 0.075),
              6px 6px 10px rgba(255, 255, 255, 0.15);

    &:hover {
      border-bottom: solid #4d4d4d;
      box-shadow: -2px -2px 6px rgba(43, 43, 43, 0.6),
                  -2px -2px 4px rgba(43, 43, 43, 0.4),
                  2px 2px 2px rgba(43, 43, 43, 0.05),
                  2px 2px 4px rgba(255, 255, 255, 0.1);
    }
    &:active {
      box-shadow: inset -2px -2px 6px rgba(43, 43, 43, 0.7),
                  inset -2px -2px 4px rgba(43, 43, 43, 0.5),
                  inset 2px 2px 2px rgba(43, 43, 43, 0.075),
                  inset 2px 2px 4px rgba(255, 255, 255, 0.15);
    }
  }
}

.lower-content {
  display: flex;
  height: 100%;

  .body-ribbon {
    display: flex;
    flex-direction: row-reverse;
    background-color: #7A709D;
    width: 60px;
    box-shadow: 10px 14px 20px -6px rgba(59, 59, 59, 0.9);

    .inner-body-ribbon {
      background-color: #75419D;
      width: 50%;
    }
  }

  .main-body {
    width: calc(100% - 60px);
  }
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;

  p {
    font-size: small;
    text-align: center !important;
    margin: 0px;
  }
}

.social-bar {
  display: flex;
  justify-content: center;
  gap: 3rem;
  padding: 3rem 0px 3rem 0px;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #4d4d4d;
    padding: 10px;
    text-decoration: none;
    font-size: small;
    width: 70px;
    color: white;

    svg {
      height: 40px;
      width: 40px;
      color: white;
    }

    &:visited {
      color: white;
    }

    border-radius: 15px;
    transition: .2s ease-in-out;
    box-shadow: -6px -6px 14px rgba(43, 43, 43, 0.7),
              -6px -6px 10px rgba(43, 43, 43, 0.5),
              6px 6px 8px rgba(43, 43, 43, 0.075),
              6px 6px 10px rgba(255, 255, 255, 0.15);

    &:hover {
      box-shadow: -2px -2px 6px rgba(43, 43, 43, 0.6),
                  -2px -2px 4px rgba(43, 43, 43, 0.4),
                  2px 2px 2px rgba(43, 43, 43, 0.05),
                  2px 2px 4px rgba(255, 255, 255, 0.1);
    }
    &:active {
      box-shadow: inset -2px -2px 6px rgba(43, 43, 43, 0.7),
                  inset -2px -2px 4px rgba(43, 43, 43, 0.5),
                  inset 2px 2px 2px rgba(43, 43, 43, 0.075),
                  inset 2px 2px 4px rgba(255, 255, 255, 0.15);
    }
  }
}

.hidden {
  display: none;
}

.show {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;

  background-color: #4d4d4d91;

  img {
    border-radius: 10px;
    width: auto;
    height: 65%;
    margin: auto;
    overflow: hidden;
  }

  button {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #4d4d4d;
    padding: 10px;
    text-decoration: none;
    font-size: small;
    width: 200px;
    height: 50px;
    color: white;
    border: none;
    border-bottom: solid #7A709D;
    border-radius: 15px;
    margin-bottom: 5vh;

    svg {
      height: 40px;
      width: 40px;
      color: white;
    }

    transition: .2s ease-in-out;
    box-shadow: -6px -6px 14px rgba(43, 43, 43, 0.7),
              -6px -6px 10px rgba(43, 43, 43, 0.5),
              6px 6px 8px rgba(43, 43, 43, 0.075),
              6px 6px 10px rgba(255, 255, 255, 0.15);

    &:hover {
      border-bottom: solid #4d4d4d;
      cursor: pointer;
      box-shadow: -2px -2px 6px rgba(43, 43, 43, 0.6),
                  -2px -2px 4px rgba(43, 43, 43, 0.4),
                  2px 2px 2px rgba(43, 43, 43, 0.05),
                  2px 2px 4px rgba(255, 255, 255, 0.1);
    }
  }
}

@media only screen and (max-width: 800px) {
  .nav {
    padding: 0px;

    .nav-item {
      padding: 0px;
      width: 100%;
      height: 3rem;

      box-shadow: none;
      border-radius: 0px;
      border-bottom: solid #7A709D;

      &:hover {
        box-shadow: inset 5px 8px 20px 0px rgba(43, 43, 43, 0.7);
      }

      &:active {
        box-shadow: none;
      }
    }
  }

  .social-bar {
    gap: 0px;
    width: 100%;

    a {
      width: 100%;
      border-radius: 0px;
      box-shadow: none;
      border-bottom: solid #7A709D;
      border-top: solid #7A709D;

      &:hover {
        box-shadow: inset 5px 8px 20px 0px rgba(43, 43, 43, 0.7);
      }

      &:active {
        box-shadow: none;
      }
    }
  }

  .footer {
    p {
      margin: 0px !important;      
    }
  }
}

@media only screen and (max-width: 560px) {

  .main-body {
    width: 100%;
  }

  .lower-content {
    .main-body {
      width: 100%;
    }

    .body-ribbon {
      display: none;
    }
  }

  .landing {
    .splash-content {
      display: flex;
      justify-content: center;

      .landing-ribbon {
        display: none;
      }
      
      .splash {
        width: 80vw;
        height: 40vh;
        margin: 2rem 0px 0px 0px;
      }
    }

    .mid-ribbon-container {
      .mid-ribbon-corner-l, .mid-ribbon-corner-r {
        display: none;
      }
      .mid-ribbon {
        width: 100%;
      }
    }
  }

  .nav {
    flex-direction: column;
    padding-bottom: 1rem;
    width: 100%;
  }

  .social-bar {
    padding: 1rem 0px 1rem 0px !important;
  }
}