.design {
  display: flex;
  flex-direction: column;
  align-items: center;

  p, h1, .design-introduction, .design-projects {
    padding: 0px 3rem 0px 3rem;
  }

  .design-skills-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    .design-skills-container, .design-software-container {
      display: flex;
      width: 100%;
      margin: 0rem 0rem 0.5rem 0rem;

      h3 {
        width: 8rem;
        display: flex;
        flex-direction: row-reverse;
        margin: 0px;
      }

      ul {
        margin: 0px;
        padding: 0px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        
        li {
          display: inline;
          padding: 0px 0em 0px 0.5em;
        }
      }
    }
  }

  .design-projects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    grid-auto-rows: minmax(100px, auto);
    margin-top: 3rem;

    .design-project {
      display: flex;
      flex-direction: column;
      margin: 0rem 2rem 5rem 2rem;

      h2, p {
        margin: 0px;
      }

      img {
        width: 30vw;
        height: auto;
        border-radius: 10px;
        margin: 2.5rem auto auto auto;

        box-shadow: -6px -6px 14px rgba(43, 43, 43, 0.7),
        -6px -6px 10px rgba(43, 43, 43, 0.5),
        6px 6px 8px rgba(43, 43, 43, 0.075),
        6px 6px 10px rgba(43, 43, 43, 0.15);
      }
    }
  }
}

@media only screen and (max-width: 560px) {
  .design {
    p, h1, .design-introduction, .design-projects {
      padding: 0px 1rem 0px 1rem;
    }

    .design-projects {
      display: flex;
      flex-direction: column;
    }
  }
}